import React from 'react'
import Nav from './Nav'

const Header = () => {
  return (
    <div>
      <Nav />
    </div>
  )
}

export default Header